body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.callback-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

label {
  margin-bottom: 10px;
}

input {
  margin-left: 10px;
}

button {
  margin-top: 10px;
}

p {
  margin-top: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
